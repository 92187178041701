<template>
  <v-app
    class="pa-0 ma-0"
    :style="
      'background: url(' +
      require('./assets/images/main_pizza_bg.png') +
      ') no-repeat;background-attachment: fixed; background-position: top center;background-size: cover;'
    "
  >
    <!-- <v-parallax :src="require('./assets/images/main_pizza_bg.png')"> -->
    <v-sheet
      v-if="is_offline"
      class="pa-4"
      style="
        background: rgba(255, 0, 0, 0.15);
        display: grid;
        grid-row-gap: 0rem;
      "
    >
      <div style="font-weight: bold; font-size: 2rem">Trenutno odmaramo.</div>
      <div style="color: #fff">
        Primamo online narudžbe u toku našeg radnog vremena i to svakim danom
        osim nedjelje od 08:00 do 22:30.
      </div>
    </v-sheet>

    <v-container fluid class="pa-0 ma-0">
      <v-row no-gutters>
        <v-col cols="10" style="opacity: 0.25">trkacica.com</v-col>
        <v-col cols="1">
          <v-btn
            :class="is_active_locale('hr')"
            variant="plain"
            @click="changeLocale('hr')"
            >Bosanski</v-btn
          >
        </v-col>
        <v-col cols="1">
          <v-btn
            :class="is_active_locale('en')"
            variant="plain"
            @click="changeLocale('en')"
            >English</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <!-- header -->

    <v-container fluid class="pa-0 ma-0 mx-auto" style="max-width: 90%">
      <v-row>
        <v-col
          align-self="center"
          xxl="2"
          xl="2"
          lg="5"
          md="6"
          sm="12"
          xs="12"
          cols="12"
        >
          <div
            class="px-4"
            style="
              display: grid;
              align-items: center;
              grid-template-columns: max-content 1fr;
              grid-column-gap: 1rem;
            "
          >
            <v-img
              class="mx-auto"
              width="10rem"
              :src="require('./assets/images/main_logo.png')"
            ></v-img>

            <span style="text-align: left" class="maestralno">Maestralno</span>
          </div>
        </v-col>
        <v-col
          align-self="center"
          xxl="1"
          xl="2"
          lg="2"
          md="6"
          sm="12"
          xs="12"
          cols="12"
        >
          {{ $t("office_hours") }}
        </v-col>
        <v-col
          align-self="center"
          xxl="1"
          xl="2"
          lg="3"
          md="6"
          sm="12"
          xs="12"
          cols="12"
        >
          <div style="display: grid; grid-template-columns: 1fr 1fr">
            <div
              style="
                display: grid;
                align-items: center;
                grid-template-columns: max-content 1fr;
                grid-column-gap: 0.5rem;
              "
            >
              <v-icon size="x-large">mdi-cellphone</v-icon>
              <span style="font-size: 1.4rem">062 400 004</span>
            </div>
            <div
              style="
                display: grid;
                align-items: center;
                grid-template-columns: max-content 1fr;
                grid-column-gap: 0.5rem;
              "
            >
              <v-icon size="x-large">mdi-phone</v-icon>
              <span style="font-size: 1.4rem">035 204 044</span>
            </div>
          </div>
        </v-col>
        <v-col
          align-self="center"
          xxl="1"
          xl="2"
          lg="2"
          md="6"
          sm="12"
          xs="12"
          cols="12"
        >
          <v-btn
            :disabled="is_disabled"
            :variant="is_disabled ? 'outlined' : null"
            rounded
            @click="open_cart"
            size="x-large"
            :style="is_disabled ? null : 'background: green;'"
          >
            <v-icon start left>mdi-cart</v-icon>
            {{ $t("order") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- /header -->

    <!-- products -->

    <v-container fluid style="max-width: 90%">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search_text"
            hide-details
            clearable
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          xxl="1"
          xl="2"
          lg="3"
          md="6"
          sm="12"
          xs="12"
          cols="12"
          v-for="product in available_products"
          :key="product.id"
          style="text-align: left"
        >
          <v-sheet
            variant="rounded"
            :class="
              product.items.filter((x) => x.quantity > 0).length
                ? 'green fill-height'
                : 'fill-height'
            "
            rounded
            style="border-radius: 0.5rem; overflow: hidden"
          >
            <v-hover v-slot="{ isHovering, props }">
              <v-img
                :src="require('./assets/images/' + product.image)"
                style="width: 100%; text-align: center; cursor: pointer"
                @click="select_product(product)"
                v-bind="props"
              >
                <v-overlay
                  :model-value="isHovering"
                  contained
                  scrim="gray darken-3"
                  class="align-center justify-center"
                >
                  <h1 class="product_name">
                    {{ product.name[$root.$i18n.locale] }}
                  </h1>
                  <div>
                    {{
                      $t("starting_from", {
                        price: get_starting_price(product),
                      })
                    }}
                  </div>
                </v-overlay>
              </v-img>
            </v-hover>

            <div
              class="pa-2 mx-2"
              style="
                display: grid;
                grid-template-columns: max-content 1fr;
                grid-column-gap: 1rem;
                align-items: center;
              "
            >
              <v-badge
                color="green"
                offset-x="-15"
                offset-y="5"
                v-if="product.items.filter((x) => x.quantity > 0).length"
                :content="product.items.filter((x) => x.quantity > 0).length"
              >
                <h1 class="product_name">
                  {{ product.name[$root.$i18n.locale] }}
                </h1>
              </v-badge>
              <h1 v-else class="product_name">
                {{ product.name[$root.$i18n.locale] }}
              </h1>
              <div style="text-align: right">
                <v-btn color="green darken-3" @click="select_product(product)">
                  <v-icon style="color: #fff">mdi-plus</v-icon>
                </v-btn>
              </div>
            </div>
            <div
              class="pa-4 pt-0"
              style="grid-column: 1 / 3; opacity: 0.75; font-size: 0.9rem"
            >
              {{ product.description[$root.$i18n.locale] }}
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <!-- /products -->

    <!-- pizza dialog -->
    <v-dialog light persistent v-model="show_dialog" width="70%" scrollable>
      <v-card v-if="selected_product !== null">
        <v-card-text
          style="
            display: grid;
            grid-row-gap: 0.5rem;
            grid-row-gap: 3px;
            height: 40rem;
          "
          class="pa-0 ma-0"
        >
          <div style="display: grid; grid-template-columns: 33% 1fr">
            <div class="fill-height">
              <v-img
                :src="require('./assets/images/' + selected_product.image)"
                class="align-end fill-height"
                style="height: 15rem"
                cover
              >
                <v-card-title
                  class="text-white"
                  style="background: rgba(0, 0, 0, 0.5)"
                >
                  <h1
                    class="product_name"
                    style="
                      font-size: 3rem;
                      line-height: 5rem;
                      font-weight: bold;
                    "
                  >
                    {{ selected_product.name[$root.$i18n.locale] }}
                  </h1>
                  <h2 class="pa-2" style="white-space: break-spaces">
                    {{ selected_product.description[$root.$i18n.locale] }}
                  </h2>
                </v-card-title>
              </v-img>
            </div>
            <div
              style="
                overflow-y: scroll;
                height: 40rem;
                border-top: solid 1px rgba(255, 255, 255, 0.1);
              "
            >
              <div
                v-for="(item, index) in selected_product.items"
                :key="'item_' + index"
                :class="item.quantity > 0 ? 'green darken-3 pa-4' : 'pa-4'"
                style="
                  display: grid;
                  align-items: center;
                  grid-column-gap: 1rem;
                  text-align: center;
                  grid-template-columns: 4fr max-content 4rem max-content 5rem;
                  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
                "
              >
                <div style="text-align: left">
                  <div
                    style="
                      display: grid;
                      grid-template-columns: max-content max-content repeat(
                          2,
                          max-content
                        );
                      align-items: center;
                      grid-column-gap: 0.5rem;
                    "
                  >
                    <v-icon>mdi-pizza</v-icon>
                    <div>{{ item.name[$root.$i18n.locale] }}</div>
                    <v-chip
                      variant="outlined"
                      label
                      size="x-small"
                      color="grey"
                      v-if="item.light"
                      outlined
                      >{{ $t("whole_wheat_flour") }}</v-chip
                    >
                    <v-chip
                      variant="outlined"
                      label
                      size="x-small"
                      color="grey"
                      v-if="item.ps"
                      outlined
                      >{{ $t("stuffed_crust_short") }}</v-chip
                    >
                  </div>
                  <div class="text-caption">
                    {{ item.description[$root.$i18n.locale] }}
                  </div>
                </div>
                <div
                  style="
                    display: grid;
                    grid-template-columns: max-content 1fr max-content;
                    align-items: center;
                    grid-column-gap: 0.25rem;
                  "
                >
                  <v-btn
                    class="pa-0 ma-0"
                    @click="decrease_quantity(item)"
                    size="small"
                    icon
                    variant="plain"
                    ><v-icon size="large">mdi-minus</v-icon></v-btn
                  >
                  <div
                    style="
                      border-radius: 0.5rem;
                      background: rgba(0, 0, 0, 0.15);
                      height: 3rem;
                      width: 3rem;
                      align-items: center;
                      display: grid;
                      border-bottom: solid 1px grey;
                    "
                  >
                    {{ item.quantity }}
                  </div>
                  <v-btn
                    class="pa-0 ma-0"
                    @click="increment_quantity(item)"
                    size="small"
                    icon
                    variant="plain"
                    ><v-icon size="large"
                      >mdi-plus-circle-outline</v-icon
                    ></v-btn
                  >
                </div>
                <div>{{ item.price }} KM</div>
                <div style="text-align: center">=</div>
                <div style="text-align: right">
                  {{ item.price * item.quantity }}
                  KM
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          class="pa-2"
          style="
            background: rgba(51, 51, 51, 0.15);
            display: grid;
            align-items: center;
            grid-template-columns: max-content 1fr max-content;
          "
        >
          <v-btn @click="close_dialog()" variant="plain">
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :max-width="100"
            rounded="l"
            variant="plain"
            @click="close_dialog()"
            >{{ $t("continue") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /pizza dialog -->
    <!-- cart dialog -->
    <v-dialog v-model="show_cart" persistent width="50%" scrollable>
      <v-card class="green" elevation="16">
        <div
          style="
            text-align: center;
            background: rgba(0, 0, 0, 0.15);
            display: grid;
            grid-template-columns: max-content 1fr max-content;
            align-items: center;
          "
          class="pa-0 last_step"
        >
          <v-btn icon variant="plain" @click="previous_step" size="x-large">
            <v-icon large icon="mdi-arrow-left"></v-icon>
          </v-btn>
          {{ current_title() }}
          <v-btn icon variant="plain" @click="next_step" size="x-large">
            <v-icon icon="mdi-arrow-right"></v-icon>
          </v-btn>
        </div>
        <v-card-text style="height: 40rem">
          <v-window v-model="checkout_step">
            <v-window-item key="`selection`" :value="0">
              <v-card color="transparent">
                <div style="display: grid; align-items: start">
                  <div style="display: grid; grid-row-gap: 1rem">
                    <div
                      v-for="(product, index) in products.filter(
                        (x) => x.total > 0 && x.ignore !== true
                      )"
                      :key="'s_' + index"
                      style="display: grid; grid-row-gap: 0.5rem"
                    >
                      <div class="product_name">
                        {{ product.name[$root.$i18n.locale] }}
                      </div>
                      <div
                        v-for="(selected, index2) in product.items.filter(
                          (x) => x.quantity > 0
                        )"
                        :key="'sp_' + index2"
                        style="
                          display: grid;
                          grid-template-columns: max-content 1fr 8rem 5rem 5rem;
                          grid-column-gap: 1rem;
                          align-items: center;
                          grid-row-gap: 2rem;
                        "
                      >
                        <v-img
                          class="left"
                          width="9rem"
                          :src="require('./assets/images/' + product.image)"
                        ></v-img>
                        <div>
                          {{ selected.name[$root.$i18n.locale] }}
                          <div class="text-caption">
                            {{ selected.description[$root.$i18n.locale] }}
                          </div>
                        </div>
                        <div
                          style="
                            display: grid;
                            grid-template-columns: max-content 1fr max-content;
                            align-items: center;
                            grid-column-gap: 0.25rem;
                          "
                        >
                          <v-btn
                            class="pa-0 ma-0"
                            @click="decrease_quantity(selected)"
                            size="small"
                            icon
                            variant="plain"
                            ><v-icon size="large">mdi-minus</v-icon></v-btn
                          >
                          <div
                            style="
                              border-radius: 0.5rem;
                              background: rgba(0, 0, 0, 0.15);
                              height: 3rem;
                              width: 3rem;
                              align-items: center;
                              display: grid;
                              border-bottom: solid 1px grey;
                              text-align: center;
                            "
                          >
                            {{ selected.quantity }}
                          </div>
                          <v-btn
                            class="pa-0 ma-0"
                            @click="increment_quantity(selected)"
                            size="small"
                            icon
                            variant="plain"
                            ><v-icon size="large"
                              >mdi-plus-circle-outline</v-icon
                            ></v-btn
                          >
                        </div>
                        <div>
                          {{ "x " + selected.price + " KM" }}
                        </div>
                        <div style="text-align: right">
                          {{ selected.price * selected.quantity + " KM" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-window-item>
            <v-window-item key="delivery" :value="1">
              <v-sheet
                v-if="show_error"
                class="mb-4 pa-4 white-text"
                variant="tonal"
                type="success"
                density="compact"
                icon="info"
                style="
                  border: solid 1px rgba(255, 255, 255, 0.15);
                  background: rgba(0, 0, 0, 0.15);
                "
                title="Molimo provjerite slijedeće vrijednosti"
              >
                <div>{{ $t("enter_details") }}</div>
                <li class="white-text">{{ error_messages }}</li>
              </v-sheet>
              <div
                style="
                  display: grid;
                  grid-row-gap: 1rem;
                  grid-template-columns: 1fr 1fr;
                  grid-column-gap: 1rem;
                "
              >
                <v-text-field
                  hide-details
                  persistent-placeholder
                  :label="$t('first_name')"
                  v-model="delivery.first_name"
                ></v-text-field>
                <v-text-field
                  hide-details
                  persistent-placeholder
                  :label="$t('last_name')"
                  v-model="delivery.last_name"
                ></v-text-field>
                <v-text-field
                  hide-details
                  persistent-placeholder
                  :label="$t('telephone')"
                  v-model="delivery.telephone"
                ></v-text-field>
                <v-text-field
                  hide-details
                  persistent-placeholder
                  :label="$t('email_address')"
                  v-model="delivery.email_address"
                ></v-text-field>
              </div>
              <div style="display: grid; grid-row-gap: 1rem" class="mt-4">
                <v-radio-group
                  hide-details
                  mandatory
                  v-model="delivery.type"
                  :label="$t('type_of_delivery')"
                >
                  <v-radio :value="0" :label="$t('delivery_type_0')"></v-radio>
                  <v-radio :value="1" :label="$t('delivery_type_1')"></v-radio>
                </v-radio-group>
                <div
                  v-if="delivery.type == 0"
                  style="display: grid; grid-row-gap: 1rem"
                >
                  <v-text-field
                    hide-details
                    persistent-placeholder
                    :label="$t('address')"
                    v-model="delivery.address"
                  ></v-text-field>
                  <v-text-field
                    hide-details
                    persistent-placeholder
                    label="Grad"
                    value="Tuzla"
                    v-model="delivery.city"
                    readonly
                  ></v-text-field>
                </div>
                <div
                  v-else
                  class="pa-4"
                  style="
                    display: grid;
                    grid-template-columns: max-content 1fr;
                    border: solid 0px rgba(255, 255, 255, 0.3);
                    align-items: center;
                    grid-column-gap: 1rem;
                    background: rgba(255, 255, 255, 0.05);
                  "
                >
                  <v-icon size="x-large">mdi-map-marker-radius</v-icon>
                  <div
                    class="text-caption"
                    v-html="
                      $t('pickup_text', {
                        link: 'https://www.google.com/maps/place/Pizza+Trka%C4%8Dica/@44.5257241,18.6941627,17z/data=!3m1!4b1!4m6!3m5!1s0x475952cba69e928b:0x65bd222e22027848!8m2!3d44.5257241!4d18.6967376!16s%2Fg%2F1ydpv9k16?entry=ttu',
                      })
                    "
                  ></div>
                </div>
                <v-textarea
                  hide-details
                  persistent-placeholder
                  :label="$t('additional_information')"
                  v-model="delivery.additional_information"
                ></v-textarea>
              </div>
            </v-window-item>
            <v-window-item key="confirmation" :value="2">
              <div
                class="pa-4 mb-4"
                style="
                  background: rgba(255, 255, 255, 0.05);
                  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
                "
              >
                <div>
                  {{ delivery.first_name + " " + delivery.last_name }}
                </div>
                <div>{{ delivery.telephone }}</div>
                <div>{{ delivery.email_address }}</div>
                <div>{{ delivery.address }}</div>
              </div>
              <div
                v-for="(product, index) in selected_products"
                :key="product.id + '_' + index"
                class="pa-4 mb-2"
                style="
                  display: grid;
                  grid-template-columns: 1fr 4rem 6rem;
                  grid-column-gap: 1rem;
                  align-items: center;
                  background: rgba(255, 255, 255, 0.05);
                  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
                "
              >
                <div>
                  <div>{{ product.parent }}</div>
                  <div class="text-caption">
                    {{ product.name + " - " + product.description }}
                  </div>
                </div>
                <div style="text-align: right">{{ product.quantity }} kom</div>
                <div style="text-align: right">
                  {{ product.price * product.quantity }} KM
                </div>
              </div>
              <div
                style="
                  display: grid;
                  grid-template-columns: 1fr max-content;
                  align-items: center;
                "
              >
                <div class="text-caption">
                  {{ $t("price_inclusive_of_vat") }}
                </div>
                <div style="font-size: 1.5rem">
                  {{
                    selected_products
                      .map((x) => {
                        return parseFloat(x.price * x.quantity);
                      })
                      .reduce((a, b) => {
                        return a + b;
                      })
                  }}
                  KM
                </div>
              </div>
            </v-window-item>
          </v-window>
        </v-card-text>
        <v-card-actions style="background: rgba(0, 0, 0, 0.15)">
          <v-btn @click="close_cart">{{ $t("close") }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="next_step" v-if="checkout_step < 2">{{
            $t("continue")
          }}</v-btn>
          <v-btn @click="send_order" v-else>{{ $t("send") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /cart dialog -->

    <!-- order confirmed dialog -->
    <v-dialog v-model="show_order_progress" width="33%" persistent>
      <v-card color="rgb(254, 250, 224)">
        <v-card-text>
          <v-img
            class="left mx-auto mb-4"
            width="13rem"
            :src="require('./assets/images/main_logo.png')"
          ></v-img>
          <div
            v-if="order_sending"
            style="
              display: grid;
              grid-row-gap: 1rem;
              color: #333;
              align-items: center;
            "
            class="mt-4"
          >
            <div class="mx-auto" style="color: #333">
              {{ $t("order_sending_in_progress") }}
            </div>
            <v-progress-linear
              indeterminate=""
              color="light-green-darken-4"
              height="20"
              style="width: 20rem"
              striped
              rounded
            ></v-progress-linear>
            <div style="text-align: center">
              <v-btn
                class="mx-auto"
                color="#333"
                variant="plain"
                @click="show_order_progress = false"
                >{{ $t("cancel") }}</v-btn
              >
            </div>
          </div>
          <div v-else style="color: #333">
            <div
              v-if="order_sent"
              style="display: grid; text-align: center; grid-row-gap: 0.5rem"
            >
              <div class="mx-auto product_name">
                {{ $t("thank_you_for_your_order") }}
              </div>
              <div>{{ $t("order_success") }}</div>
              <v-btn
                class="mx-auto my-4"
                color="#333"
                variant="tonal"
                @click="reset_order"
                >{{ $t("close") }}</v-btn
              >
            </div>
            <div
              v-else
              style="display: grid; text-align: center; grid-row-gap: 0.5rem"
            >
              <div class="mx-auto">
                {{ $t("error_encountered") }}
              </div>
              <div>{{ $t("please_contact") }}</div>
              <div>062 400 004 / 035 204 044</div>
              <br />
              <div class="mb-4">{{ $t("inconvenience") }}</div>
              <br />
              <v-btn
                class="mx-auto my-4"
                color="#333"
                variant="tonal"
                @click="show_order_progress = false"
                >{{ $t("close") }}</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-footer color="transparent" class="mt-4 mx-auto" style="max-width: 90%">
      <div style="display: grid; grid-row-gap: 1rem" class="mx-auto">
        <div
          class="mx-auto"
          :style="
            'display:grid;grid-template-columns: repeat(' +
            products.filter((x) => x.ignore !== true).length +
            ',max-content);grid-column-gap:1rem;'
          "
        >
          <v-btn
            variant="plain"
            size="small"
            @click="select_product_by_id(product.id)"
            v-for="(product, index) in products.filter(
              (x) => x.ignore !== true
            )"
            :key="index + '_product_'"
          >
            {{ product.name[$root.$i18n.locale] }}
          </v-btn>
        </div>
        <div
          v-if="1 == 2"
          class="py-4 mx-auto"
          style="
            display: grid;
            grid-column-gap: 2rem;
            grid-template-columns: repeat(2, 1fr);
          "
        >
          <a
            href="https://glovoapp.com/ba/en/tuzla/pizza-trkacica-tzl/"
            target="_new"
            ><v-img
              height="2rem"
              :src="require('./assets/images/glovo_logo.png')"
            ></v-img
          ></a>
          <a
            href="https://glovoapp.com/ba/en/tuzla/pizza-trkacica-tzl/"
            target="_new"
            ><v-img
              height="2rem"
              :src="require('./assets/images/glovo_logo.png')"
            ></v-img
          ></a>
        </div>
        <div
          v-html="$t('footer_text', { year: new Date().getFullYear() })"
          class="text-caption"
          style="
            background: transparent;
            text-align: center;
            color: rgba(255, 255, 255, 0.5);
          "
        ></div>
      </div>
    </v-footer>
    <!-- </v-parallax> -->
  </v-app>
</template>

<script>
import pizzas from "./assets/products.json";
import axios from "axios";
import moment from "moment";
export default {
  name: "app",
  data() {
    return {
      is_offline: true,
      is_loading: false,
      search_text: null,
      online_interval: null,
      products: [],
      selected_product: null,
      cart: null,
      show_dialog: false,
      show_cart: false,
      checkout_step: 0,
      show_error: false,
      show_order_progress: false,
      order_sending: false,
      order_sent: false,
      delivery: {
        first_name: null,
        last_name: null,
        telephone: null,
        email_address: null,
        type: 0,
        address: null,
        city: "Tuzla",
        additional_information: null,
      },
    };
  },
  methods: {
    get_starting_price(product) {
      return Math.min.apply(
        Math,
        product.items.map((x) => x.price)
      );
    },
    is_active_locale(locale) {
      return this.$root.$i18n.locale == locale ? "active_locale" : null;
    },
    reset_order() {
      this.show_order_progress = false;
      this.order_sending = false;
      this.order_sent = false;
      this.products.forEach((x) => {
        x.items.forEach((i) => {
          i.quantity = null;
        });
      });
    },
    select_product_by_id(id) {
      this.selected_product = this.products.find((x) => x.id == id);
      this.show_dialog = true;
    },
    validate_order_information() {
      return new Promise((resolve, reject) => {
        Object.keys(this.delivery).forEach((k) => {
          if (this.delivery[k] == null || this.delivery[k].length == 0) {
            if (
              this.delivery.type !== 1 &&
              !["additional_information"].includes(k)
            ) {
              reject(k);
            }
          }
        });
        resolve();
      });
    },
    decrease_quantity(item) {
      item.quantity -= 1;
      if (item.quantity < 1) {
        item.quantity = null;
        item.total = 0;
        if (this.selected_products.filter((x) => x.code !== "KD").length == 0)
          this.show_cart = false;
      }
    },
    increment_quantity(item) {
      item.quantity += 1;
    },
    current_title() {
      switch (this.checkout_step) {
        case 1:
          return this.$t("delivery_details");
        case 2:
          return this.$t("order_confirmation");
        default:
          return this.$t("you_have_selected");
      }
    },
    send_order() {
      //this.$confetti.start();
      this.show_cart = false;
      this.show_order_progress = true;
      this.order_sending = true;
      var order = {
        ime: this.delivery.first_name,
        prezime: this.delivery.last_name,
        email: this.delivery.email_address,
        telefon: this.delivery.telephone,
        grad: this.delivery.city,
        adresa: this.delivery.address,
        dod_info: this.delivery.additional_information,
        prioritet: this.delivery.type == 0 ? 999 : 1,
        stavke: this.selected_products.map((p) => {
          return {
            artikalid: p.id,
            kolicina: p.quantity,
          };
        }),
      };
      axios({
        method: "POST",
        url: "/trkacica/addOrder",
        contentType: "application/json",
        data: {
          order: JSON.stringify(order),
        },
      })
        .then(() => {
          this.order_sent = true;
        })
        .catch((e) => {
          console.log("exception error");
          console.log(e);
          this.order_sent = false;
        })
        .finally(() => {
          this.order_sending = false;
        });
    },
    next_step() {
      if (this.checkout_step == 1) {
        this.show_error = false;
        this.validate_order_information()
          .then(() => {
            this.checkout_step += 1;
          })
          .catch((e) => {
            this.error_messages = this.$t("error_" + e);
            this.show_error = true;
          });
      } else {
        this.checkout_step += 1;
      }
    },
    previous_step() {
      if (this.checkout_step > 0) {
        this.checkout_step -= 1;
      }
    },
    open_cart() {
      this.checkout_step = 0;
      this.show_cart = true;
    },
    close_cart() {
      this.show_cart = false;
    },
    select_product(product) {
      this.selected_product = product;
    },
    close_dialog() {
      this.selected_product = null;
      this.products.forEach((x) => {
        x.items.forEach((y) => {
          if (y.quantity !== null && y.quantity < 0) y.quantity = null;
        });
      });
    },
    get_products() {
      this.products = pizzas;
    },
    get_time_until_open() {
      return 1;
    },
    are_we_offline() {
      var dayOfWeek = parseInt(moment().format("d"));
      if (dayOfWeek !== 7) {
        var dayStart = moment()
          .set("hour", process.env.VUE_APP_START_HOUR)
          .set("minute", process.env.VUE_APP_START_MINUTE);
        var dayEnd = moment()
          .set("hour", process.env.VUE_APP_END_HOUR)
          .set("minute", process.env.VUE_APP_END_MINUTE);
        return !(
          moment().valueOf() >= dayStart && moment().valueOf() <= dayEnd
        );
      } else {
        return true;
      }
    },
    changeLocale(locale) {
      this.$vuetify.locale.current = locale;
      this.$root.$i18n.locale = locale;
      this.get_products();
    },
  },
  computed: {
    available_products() {
      if (this.search_text !== null && this.search_text.length > 1) {
        return this.products.filter(
          (x) =>
            x.ignore !== true &&
            (x.name[this.$root.$i18n.locale]
              .toLowerCase()
              .includes(this.search_text.toLowerCase()) ||
              x.description[this.$root.$i18n.locale]
                .toLowerCase()
                .includes(this.search_text.toLowerCase()))
        );
      } else {
        return this.products.filter((x) => x.ignore !== true);
      }
    },
    selected_products() {
      var s_products = [];
      this.products.forEach((p) => {
        p.items
          .filter((x) => x.quantity > 0)
          .forEach((o) => {
            s_products.push({
              id: o.id,
              code: o.code,
              quantity: o.quantity,
              price: o.price,
              description: o.description[this.$root.$i18n.locale],
              parent: p.name[this.$root.$i18n.locale],
              name: o.name[this.$root.$i18n.locale],
            });
          });
      });
      return s_products;
    },
    is_disabled() {
      if (this.is_offline) {
        return true;
      } else {
        return Boolean(
          !this.products.filter((x) => x.total > 0 && x.ignore !== true)
            .length > 0
        );
      }
    },
  },
  watch: {
    "delivery.type": {
      handler() {
        var dp = null;
        if (this.delivery.type == 0) {
          if (
            this.selected_products.filter((x) => x.code == "KD").length == 0
          ) {
            dp = this.products.length
              ? this.products
                  .find((x) => x.id == 13)
                  .items.find((p) => p.code == "KD")
              : null;
            if (dp !== null) dp.quantity = 1;
          }
        } else {
          if (
            this.selected_products.filter((x) => x.code == "KD").length == 1
          ) {
            dp = this.products.length
              ? this.products
                  .find((x) => x.id == 13)
                  .items.find((p) => p.code == "KD")
              : null;
            if (dp !== null) dp.quantity = 0;
          }
        }
      },
      immediate: true,
    },
    selected_product: {
      handler() {
        this.show_dialog = this.selected_product !== null;
      },
    },
    products: {
      handler() {
        this.products.forEach((p) => {
          var selected_items = p.items.filter(
            (x) => x.quantity !== null && x.quantity > 0
          );
          p.total = selected_items.length
            ? selected_items
                .map((x) => x.price * x.quantity)
                .reduce((a, b) => {
                  return a + b;
                })
            : 0;
        });
      },
      deep: true,
    },
  },
  mounted() {
    console.clear();
    this.get_products();
    console.log("****** PIZZA TRKAČICA ******");
    this.is_offline = this.are_we_offline();
    this.online_interval = setInterval(() => {
      this.is_offline = this.are_we_offline();
    }, 60 * 1000);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  background-position: center center;
  background-size: cover;
}

.v-overlay__scrim::before {
  filter: blur(10px);
}
.v-overlay__scrim {
  backdrop-filter: blur(15px) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: rgb(51, 51, 51) !important;
  opacity: 0.85 !important;
}

.green {
  background: rgba(40, 54, 24, 1) !important;
}

.maestralno {
  font-family: "Satisfy", cursive;
  font-size: 4rem;
  font-weight: bold;
  font-style: italic;
}

.product_name {
  font-family: "Satisfy", cursive;
  font-size: 2rem;
  font-style: italic;
  line-height: 3rem;
  font-weight: bold;
}

.last_step {
  font-family: "Satisfy", cursive;
  font-size: 2rem !important;
  font-style: italic;
  font-weight: bold;
}

.active_locale {
  border-bottom: solid 2px white !important;
}

h2 {
  font-size: 1rem;
  line-height: 1.3rem;
  font-weight: normal;
}
</style>
